import { Injectable } from '@angular/core';
import { DOMAIN_COMPANY } from '@app/data/constants/domains';
import { ServerErrorService } from '@core/services/server-error.service';
import { mapToCriteriaResponse } from '@core/utils/map-to-criteria-response';
import { ICriteriaResponse } from '@models/ICriteriaResponse';
import { IEmployee } from '@models/IEmployee';
import { DatabaseProvider } from '@paella-front/ngx-database';
import { Database } from '@paella-front/ngx-database/lib/database';
import {
  HttpCriteria,
  SuperHttpClientProvider,
} from '@paella-front/ngx-super-http-client';
import { BehaviorSubject, Observable } from 'rxjs';
const EMPLOYEE_SELECTED = 'employee_selected';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  _employee$: BehaviorSubject<IEmployee | null> = new BehaviorSubject<IEmployee | null>(
    null
  );
  _employees$ = new BehaviorSubject<Array<IEmployee> | null>(null);
  _refreshEmployees$ = new BehaviorSubject<boolean>(false);
  private readonly $ehttp: ReturnType<SuperHttpClientProvider['use']>;

  /////////////////
  // Constructor //
  /////////////////

  constructor(
    private $db: DatabaseProvider,
    $$shttp: SuperHttpClientProvider,
    public $serverError: ServerErrorService
  ) {
    this.$ehttp = $$shttp.use(DOMAIN_COMPANY);
  }

  ///////////////////////
  // Private Accessors //
  ///////////////////////

  ///////////////////////
  // Public Accessors //
  ///////////////////////

  get employee$(): Observable<IEmployee | null> {
    return this._employee$.asObservable();
  }

  get employees$(): Observable<Array<IEmployee | null>> {
    return this._employees$.asObservable();
  }

  get refreshEmployees$(): Observable<boolean> {
    return this._refreshEmployees$.asObservable();
  }

  set employee(data: IEmployee | null) {
    this._employee$.next(data);
    this.setEmployeeSelected(data);
  }

  set employees(data: Array<IEmployee> | null) {
    this._employees$.next(data);
  }

  set refreshEmployees(value: boolean) {
    this._refreshEmployees$.next(value);
  }

  async setEmployeeSelected(value: IEmployee): Promise<void> {
    await (this.$db.default as Database).set(EMPLOYEE_SELECTED, value);
  }

  async getEmployeeSelected(): Promise<IEmployee> {
    return await (this.$db.default as Database).get(EMPLOYEE_SELECTED);
  }

  list(
    criteria: HttpCriteria,
    companyId: number
  ): Observable<ICriteriaResponse<IEmployee>> {
    return this.$ehttp
      .filter(`/employee/${companyId}/search`, criteria, { observe: 'response' })
      .pipe(mapToCriteriaResponse());
  }

  get(id: number, companyId: number): Observable<IEmployee> {
    return this.$ehttp.get<IEmployee>(`/employee/${companyId}/${id}`);
  }

  create(data: IEmployee): Observable<IEmployee> {
    return this.$ehttp.post<IEmployee>(`/employee/${data.companyId}`, data);
  }

  edit(id: number, data: IEmployee): Observable<IEmployee> {
    return this.$ehttp.put<IEmployee>(`/employee/${data.companyId}/${id}`, data);
  }

  delete(id: number, companyId: number, value: number = 1): Observable<boolean> {
    return this.$ehttp.delete<boolean>(`/employee/${companyId}/${id}/${value}`);
  }

  addImage(companyId: number, employeeId: number, file: File): Observable<IEmployee> {
    const formData = new FormData();
    formData.append('file', file);
    return this.$ehttp.post<IEmployee>(
      `/employee/${companyId}/${employeeId}/image`,
      formData
    );
  }

  deleteImage(companyId: number, employeeId: number): Observable<IEmployee> {
    return this.$ehttp.delete<IEmployee>(`/employee/${companyId}/${employeeId}/image`);
  }

  deletePhysically(id: number, companyId: number): Observable<IEmployee> {
    return this.$ehttp.delete<IEmployee>(`/employee/${companyId}/${id}`);
  }
}
